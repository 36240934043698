import {LitElement, html, css} from 'lit';
import {customElement, query, state} from 'lit/decorators.js'; // eslint-disable-line import/extensions
import {classMap} from 'lit/directives/class-map.js'; // eslint-disable-line import/extensions

const hasDialog = document.createElement('dialog')?.showModal !== undefined;

@customElement('modal-element')
export class ModalElement extends LitElement {
  @state()
  private _opened = false;

  @query('dialog')
  private accessor _dialog: HTMLDialogElement | null = null;

  static legacyStyles = css`
    .wrapper {
      opacity: 0;
      visibility: hidden;
      position: fixed;
      left: 0;
      top: 0;
      z-index: 9001;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: opacity 0.25s ease-in;
    }
    .wrapper.open {
      opacity: 1;
      visibility: visible;
    }
    .overlay {
      background: rgb(0, 0, 0, 0.8);
      height: 100%;
      width: 100%;
      position: relative;
    }
    .dialog.legacy {
      position: fixed;
    }
  `;

  static modernStyles = css`
    dialog:not([open]) {
      display: none;
    }
    dialog {
      opacity: 0;
      transition: all 0.25s allow-discrete;
    }
    dialog[open] {
      opacity: 1;
    }
    @starting-style {
      dialog[open] {
        opacity: 0;
      }
    }
    dialog::backdrop {
      backdrop-filter: blur(1px);
      background-color: rgb(0, 0, 0, 0);
      transition: all 0.25s allow-discrete;
    }
    dialog[open]::backdrop {
      background-color: rgb(0, 0, 0, 0.8);
    }
    @starting-style {
      dialog[open]::backdrop {
        background-color: rgb(0, 0, 0, 0);
      }
    }
  `;

  static styles = [
    hasDialog ? ModalElement.modernStyles : ModalElement.legacyStyles,
    css`
      .dialog {
        background: #ffffff;
        height: 95%;
        width: 90%;
        box-sizing: border-box;
        border: none;
        border-radius: 16px;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        overflow: visible;
      }
      .content {
        flex: 1 1 auto;
      }
      .modal-headline {
        display: flex;
        align-items: center;
      }
      .modal-title {
        flex: 1 1 auto;
        font-size: 1.4rem;
      }
    `,
  ];

  renderDialogContent() {
    return html`
      <div class="modal-headline">
        <div class="modal-title"><slot name="title"></slot></div>
        <md-icon-button
          class="close-button"
          @click="${this.close}"
        ><md-icon>close</md-icon></md-icon-button>
      </div>
      <div class="content"><slot></slot></div>
    `;
  }

  renderLegacy() {
    return html`
      <div class="${classMap({wrapper: true, open: this._opened})}">
        <div class="overlay"></div>
        <div
          class="dialog legacy"
          @keydown="${this._handleKeydown}"
          tabindex="0"
        >
          ${this.renderDialogContent()}
        </div>
      </div>
    `;
  }

  renderModern() {
    return html`
      <dialog
        class="dialog"
        @close="${this._dialogClose}"
      >
        ${this.renderDialogContent()}
      </dialog>
    `;
  }

  render() {
    return hasDialog ? this.renderModern() : this.renderLegacy();
  }

  async open() {
    if (this._opened) {
      return;
    }
    this.dispatchEvent(new Event('open'));
    this._opened = true;
    this._dialog?.showModal();
    await this.updateComplete.then(() => this._legacyModalSetFocus());
  }

  async close() {
    if (!this._opened) {
      return;
    }
    this.dispatchEvent(new Event('close'));
    this._opened = false;
    this._dialog?.close();
  }

  private _dialogClose() {
    this.close();
  }

  private _legacyModalSetFocus() {
    this.renderRoot.querySelector<HTMLDivElement>('.dialog.legacy')?.focus();
  }

  private _handleKeydown(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.close();
    }
  }
}
